// 我加入的企业
// mxy
<template>
  <div class="main flex-column">
    <a-tabs type="card" >
      <a-tab-pane key="1" tab="加入或邀请">
        <!-- <a-form-item class="inputSearchs">
              <a-input-search
                placeholder="搜索消息"
                size="large"
                :maxLength="60"
                type="text"
                @search="onSearch"
              />
            </a-form-item> -->
        <a-spin :spinning="spinning">
          <a-empty
            v-if="descriptionTotals === 0 && applyJionListTotals === 0"
            description="暂无数据"
          />
          <a-form-item class="cards" :wrapper-col="{ span: 24 }">
            <!-- 邀请记录 -->
            <div
              class="card flex-row"
              :key="item.id"
              v-for="item in invitationList"
            >
              <div class="flex-row">
                <img
                  v-if="item.enterpriseLogo !== null"
                  class="logo"
                  :src="item.enterpriseLogo"
                />
                <img
                  v-if="item.enterpriseLogo === null"
                  class="logo"
                  src="@/assets/icon_company_weishangchang.svg"
                />
                <div class="describe">
                  <div :title="item.enterpriseName" class="enterpriseName">
                    {{ item.enterpriseName }}
                  </div>
                  <div class="flex-row justify-between">
                    <div class="forntColor">ID：{{ item.enterpriseCode }}</div>
                    <div class="forntColor">
                      类型：<span class="state invitationState">邀请</span>
                    </div>
                  </div>
                  <div class="flex-row justify-between">
                    <div class="forntColor">
                      加入时间：{{ item.creationTimeS }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.valid == false">
                <div
                  style="background-color: #f5f5f5"
                  class="jionBtn align-center"
                >
                  {{
                    item.beUse == false
                      ? "已拒绝"
                      : item.beUse == true
                      ? "已同意"
                      : "已失效"
                  }}
                </div>
              </div>
              <div v-if="item.valid == true">
                <div class="jionBtns">
                  <a-button
                    class="btn align-center"
                    type="primary"
                    :loading="state.loginBtn"
                    @click="acceptInvitation(item)"
                    >同意</a-button
                  >
                  <a-button
                    class="align-center"
                    type="danger"
                    @click="refuseInvitation(item)"
                    >拒绝</a-button
                  >
                </div>
              </div>
            </div>
            <!-- 申请记录 -->
            <div class="card flex-row" :key="item" v-for="item in joinList">
              <div class="flex-row">
                <img
                  v-if="item.enterpriseLogo !== null"
                  class="logo"
                  :src="item.enterpriseLogo"
                />
                <img
                  v-if="item.enterpriseLogo === null"
                  class="logo"
                  src="@/assets/icon_company_weishangchang.svg"
                />
                <div class="describe">
                  <div :title="item.enterpriseName" class="enterpriseName">
                    {{ item.enterpriseName }}
                  </div>
                  <div class="flex-row justify-between">
                    <div class="forntColor">ID：{{ item.enterpriseCode }}</div>
                    <div class="forntColor">
                      类型：<span class="state joinState">{{
                        item.sourceJoin
                      }}</span>
                    </div>
                  </div>
                  <div class="flex-row justify-between">
                    <div class="forntColor">
                      加入时间：{{ item.creationTimeS }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.audited == true">
                <div
                  class="jionBtn align-center"
                  style="background-color: #f5f5f5; color: rgba(0, 0, 0, 0.85)"
                >
                  {{ item.auditResult == false ? "审核未通过" : "审核通过" }}
                </div>
              </div>
              <div v-if="item.audited == false">
                <div
                  class="jionBtn align-center"
                  style="background-color: #ff121f; color: #ffff"
                >
                  待审核
                </div>
              </div>
            </div>
          </a-form-item>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="2" tab="搜索名称/ID">
        <a-form-item class="inputSearch">
          <a-input-search
            placeholder="搜索名称，企业Id"
            size="large"
            :maxLength="60"
            type="text"
            @search="onSearch"
          />
        </a-form-item>
        <a-spin :spinning="spinning">
          <a-empty v-if="total === 0" description="暂无数据" />
          <a-form-item class="inputCard" :wrapper-col="{ span: 24 }">
            <div
              class="card flex-row align-between"
              :key="index"
              v-for="(item, index) in obj.data"
            >
              <div class="flex-row">
                <img
                  v-if="item.enterpriseLogo !== null"
                  class="logo"
                  :src="item.enterpriseLogo"
                />
                <img
                  v-if="item.enterpriseLogo === null"
                  class="logo"
                  src="@/assets/icon_company_weishangchang.svg"
                />
                <div class="describe">
                  <div :title="item.enterpriseName" class="enterpriseName">
                    {{ item.enterpriseName }}
                  </div>
                  <div class="flex-row justify-between">
                    <div class="forntColor">ID：{{ item.enterpriseCode }}</div>
                    <div class="forntColor location">
                      地址：<span :title="item.location">{{
                        item.location
                      }}</span>
                    </div>
                  </div>
                  <div class="flex-row justify-between">
                    <div class="forntColor">
                      创建时间：{{ item.creationTime }}
                    </div>
                    <div class="forntColor">
                      状态：
                      <span class="state objState">{{
                        item.enterpriseProofName
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <a-button
                  class="jionBtn align-center"
                  type="primary"
                  @click="jion(item)"
                  :disabled="item.joined == false ? false : true"
                  >{{ item.joined == false ? "加入" : "已加入" }}</a-button
                >
              </div>
            </div>
          </a-form-item>
        </a-spin>
        <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
          <div>
            <a-pagination
              hideOnSinglePage
              v-model:current="pageCount"
              v-model:pageSize="pageSize"
              :total="total"
              @change="pageChange"
            />
          </div>
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import dayjs from 'dayjs'
import { minheight } from '../../components/sameVariable'

export default defineComponent({
  data () {
    return {
      disabled: false,
      title: '暂无权限',
      spinning: false, // 是否加载
      externalGenreList: [],
      searchInput: '',
      obj: {},
      total: 0,
      page: 1,
      pageSize: 10,
      pageCount: 1,
      state: {
        loginBtn: false,
      },
      // description: '暂无记录',
      row: {},
      activeKey: 1,
      joinList: [],
      invitationList: [],
      descriptionTotals: 0,
      applyJionListTotals: 0,
      sum: 0,
      titleListTime: [],
    }
  },
  created () {
    this.minHeight = minheight
    this.getApplyJionList()
    this.getInvitationList()
  },
  methods: {
    // 同意
    acceptInvitation (item) {
      this.spinning = true
      this.state.loginBtn = true
      const obj = {
        id: item.id,
        shortKey: item.ShortKey,
      }
      api
        .post('/api/app/employee-invitation/accept-invitation', obj)
        .then(({ data }) => {
          this.spinning = false
          this.state.loginBtn = false
          this.$message.success('已同意邀请！')
          this.getInvitationList()
        })
        .catch(err => {
          this.spinning = false
          this.state.loginBtn = false
          console.log(err)
        })
    },
    // 拒绝
    refuseInvitation (item) {
      this.spinning = true
      this.state.loginBtn = true
      const obj = {
        id: item.id,
      }
      api
        .post('/api/app/employee-invitation/refuse-invitation', obj)
        .then(({ data }) => {
          this.$message.success('已拒绝邀请!')
          this.getInvitationList()
          this.spinning = false
          this.state.loginBtn = false
        })
        .catch(err => {
          this.state.loginBtn = false
          // this.$message.error('操作失败' + err)
          this.spinning = false
          console.log(err)
        })
    },
    // 切换tab页
    // callbackTab (activeKey) {
    //   console.log(activeKey)
    //   if (activeKey !== '3') {
    //     this.handleFocus()
    //   }
    // },
    // 获取申请数据
    async getApplyJionList () {
      // await this.getInvitationList()
      this.spinning = true
      api
        .get('/api/app/ent-apply-join/user-apply-join-list')
        .then(({ data }) => {
          this.joinList = data
          this.spinning = false
          this.applyJionListTotals = data.length

          // this.state.loginBtn = false
          // this.$message.success('创建成功！')
        })
        .catch(err => {
          console.log(err)
          this.spinning = false
          // this.state.loginBtn = false
          // this.$message.error('创建失败' + err)
        })
    },
    // 获取邀请请数据
    getInvitationList () {
      api
        .get('/api/app/employee-invitation/active-user-invitation-list')
        .then(({ data }) => {
          this.invitationList = data
          this.descriptionTotals = data.length
          this.spinning = false
          // this.sum = Number(this.descriptionTotals) + Number(this.applyJionListTotals)
          // console.log(this.sum)
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error(err)
          console.log(err)
        })
    },
    // 搜索
    onSearch (value) {
      this.page = 1
      this.pageCount = 1
      this.searchInput = value
      if (this.searchInput === '') {
        this.obj = {}
        this.total = 0
        return
      }
      this.getEnterpriseSearch()
    },
    // 切换分页
    pageChange (page) {
      this.page = page
      this.getEnterpriseSearch()
    },
    // 获取搜索数据
    getEnterpriseSearch () {
      this.spinning = true
      api
        .get('/api/app/sys-enterprise-info/enterprise-info-list-by-search', {
          params: {
            searchInput: this.searchInput,
            pageSize: this.pageSize,
            pagination: this.page,
          },
        })
        .then(({ data }) => {
          this.obj = data
          this.row = data.data
          this.row.forEach(element => {
            element.creationTime = dayjs(element.creationTime).format('YYYY-MM-DD HH:mm:ss')
          })
          this.total = data.total
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('数据获取失败' + err)
          console.log(err)
        })
    },
    // 点击加入
    jion (value) {
      this.spinning = true
      const obj = {
        tenantId: value.tenantId,
      }
      api
        .post('/api/app/ent-apply-join/ent-apply-join', obj)
        .then(({ data }) => {
          // console.log(data)
          this.$message.success(data)
          this.getEnterpriseSearch()
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('加入失败' + err)
          console.log(err)
        })
    },
    // 点击选择所属行业
    handleChange (value, option) {
      this.industryId = value
      option.children.forEach(element => {
        this.industryIdName = element.children
      })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  background: #fff;
  position: relative;
  .inputSearch {
    position: absolute;
    right: 0;
    top: 0;
  }
  .inputSearchs {
    position: relative;
    left: 642px;
    top: -56px;
  }
  .inputCard {
    position: relative;
    // top: -60px;
  }
  .cards {
    position: relative;
    // top: -116px;
    .spinning {
      margin: auto;
    }
    .ant-empty {
      margin: 95px 8px;
    }
  }
  .card {
    width: 100%;
    height: 140px;
    margin: 20px 0;
    border-bottom: 1px solid #ccc;
    position: relative;
    .describe {
      width: 533px;
      margin-left: 10px;
    }
    .logo {
      width: 50px;
      height: 50px;
      // background-color: #134CCF;
    }
    .enterpriseName {
      width: 300px;
      height: 24px;
      line-height: 24px;
      margin-bottom: 16px;
      font-size: 18px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .forntColor {
      color: #999;
      width: 40%;
    }
    .location {
      width: 40%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .state {
      display: inline-block;
      text-align: center;
      border-radius: 3px;
    }
    .objState {
      width: 54px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #e6002d;
      background-color: #ffe3e7;
      border: 1px solid #e6002d;
    }
    .joinState {
      min-width: 40px;
      height: 20px;
      padding: 0 4px;
      font-size: 12px;
      line-height: 20px;
      background-color: #ffb95b;
      color: #fff;
    }
    .invitationState {
      width: 40px;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      background-color: #134ccf;
      color: #fff;
    }
    .jionBtn {
      position: absolute;
      top: 60px;
      right: 0%;
      height: 34px;
      width: 80px;
      line-height: 34px;
      text-align: center;
      // border: 1px solid #ccc;
      // background-color: #ccc;
      // color: #fff;
    }
    .jionBtns {
      float: right;
      position: absolute;
      top: 60px;
      right: 0;
      .btn {
        margin-right: 20px;
      }
    }
  }
  .bottom {
    margin-top: -20px;
    text-align: right;
    font-size: 12px;
  }
}
</style>
